import React, { useState, useEffect } from 'react';
import './Modal.css';
import './MobileModal.css';
import renderSVG from './SVGICONS/ModalSVG';
import domtoimage from 'dom-to-image';
import * as PIXI from 'pixi.js';

function Ordinal({ ordinal, showDownloadButton }) {
    const [showModal, setShowModal] = useState(false);
    const [buttonText, setButtonText] = useState({ bear: "Download Bear", card: "Download Card" });

    function getTextColorClass(rarityrank) {
        if (rarityrank < 100) {
            return 'red-rarity';
        } else if (rarityrank < 1000) {
            return 'gold-rarity';
        } else if (rarityrank < 5000) {
            return 'white-rarity';
        } else {
            return 'gray-rarity';
        }
    }

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth <= 520) {
                setButtonText({ bear: "HD Bear", card: "Card" });
            } else {
                setButtonText({ bear: "Download HD Bear", card: "Download Card" });
            }
        }
        window.addEventListener('resize', handleResize);
        handleResize(); // call the function initially to set the correct button text

        // Remember to cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    function downloadImage() {
        const modalContent = document.querySelector('.modal-content');
        const ordinalId = ordinal.id;
        const downloadButtons = document.querySelectorAll('.download-button');

        // Add the CSS class to hide the buttons
        downloadButtons.forEach(btn => btn.classList.add('hide-for-screenshot'));

        // Define a scale for increasing the resolution
        const scale = 2;

        // Define the options for domtoimage
        const options = {
            width: modalContent.offsetWidth * scale,
            height: modalContent.offsetHeight * scale,
            style: {
                transform: `scale(${scale})`,
                transformOrigin: 'top left',
                width: `${modalContent.offsetWidth}px`,
                height: `${modalContent.offsetHeight}px`
            }
        };

        domtoimage.toBlob(modalContent, options)
            .then((blob) => {
                const url = URL.createObjectURL(blob);

                const a = document.createElement('a');
                a.href = url;
                a.download = `${ordinalId}.png`; // Set the filename to ordinal.id.png
                a.click();

                URL.revokeObjectURL(url);

                // Remove the CSS class to show the buttons again
                downloadButtons.forEach(btn => btn.classList.remove('hide-for-screenshot'));
            })
            .catch((error) => {
                console.error('Error generating image:', error);

                // Ensure the class is removed even if an error occurs
                downloadButtons.forEach(btn => btn.classList.remove('hide-for-screenshot'));
            });
    }

    function downloadBear() {
        const ordinalId = ordinal.id;
        const url = `/build/${ordinalId}.png`; 
    
        const img = new Image();
        img.crossOrigin = "Anonymous"; 
        img.onload = function() {
            const smallCanvas = document.createElement('canvas');
            const largeCanvas = document.createElement('canvas');
    
            smallCanvas.width = 600;
            smallCanvas.height = 600;
            largeCanvas.width = 2000;
            largeCanvas.height = 2000;
    
            const smallApp = new PIXI.Application({ 
                width: 600, 
                height: 600, 
                view: smallCanvas
            });
    
            const largeApp = new PIXI.Application({ 
                width: 2000, 
                height: 2000, 
                view: largeCanvas
            });
    
            let base = new PIXI.BaseTexture(img);
            let texture = new PIXI.Texture(base);
    
            let sprite = new PIXI.Sprite(texture);
    
            sprite.anchor.set(0.5);
    
            sprite.width = smallApp.screen.width;
            sprite.height = smallApp.screen.height;
    
            sprite.x = smallApp.screen.width / 2;
            sprite.y = smallApp.screen.height / 2;
    
            PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST;
    
            smallApp.stage.addChild(sprite);
    
            smallApp.renderer.render(smallApp.stage);
    
            // Now draw the small canvas onto the large canvas
            const largeBase = new PIXI.BaseTexture(smallCanvas);
            const largeTexture = new PIXI.Texture(largeBase);
    
            const largeSprite = new PIXI.Sprite(largeTexture);
    
            largeSprite.anchor.set(0.5);
    
            largeSprite.width = largeApp.screen.width;
            largeSprite.height = largeApp.screen.height;
    
            largeSprite.x = largeApp.screen.width / 2;
            largeSprite.y = largeApp.screen.height / 2;
    
            largeApp.stage.addChild(largeSprite);
    
            largeApp.renderer.render(largeApp.stage);
    
            const a = document.createElement('a');
            a.download = `${ordinalId}.png`;
            a.href = largeCanvas.toDataURL('image/png');
            a.click();
    
            smallApp.destroy(true);
            largeApp.destroy(true);
        };
        img.src = url;
    }

    function toggleModal() {
        setShowModal(!showModal);
    }

    function closeModal(event) {
        if (event.target.classList.contains('modal-background')) {
            setShowModal(false);
        }
    }

    const background = ordinal.attributes.find(
        attribute => attribute.trait_type === 'Backgrounds'
    )?.value;

    const special = ordinal.attributes.find(
        attribute => attribute.trait_type === 'Special Bears'
    )?.value;

    return (
        <>
            <div className="ordinal">
                <div className="image-container" onClick={toggleModal}>
                    <div className="image-wrapper">
                        <img className="pixelated" src={ordinal.image} alt={`Ordinal #${ordinal.id}`} />
                        <div className={`image-text ${getTextColorClass(ordinal.rarityrank)}`}>{ordinal.rarityrank}</div>
                    </div>
                </div>
                <div className='buynow-flex'>
                    <p className="ordinal-price"></p>
                    <div className='me-logo'>
                    <a href={ordinal.magiclink} target="_blank" rel="noreferrer">
                        <img className="magic-eden-logoo" src="/MELOGO.png" alt="Logo" />
                    </a>
                    </div>
                </div>
                <p className="ordinal-title">Bitcoin Bear</p>
                <h3 className="ordinal-number">NO. #{ordinal.id}</h3>
            </div>
            {showModal && (
                <div className="modal-background" onClick={closeModal}>
                    <div className="modal-backdrop">
                        <div className="modal-content" data-background={background} data-special={special}>
                            <div className="modal-image">
                                <img className="pixelated" src={ordinal.image} alt={`Ordinal #${ordinal.id}`} />
                                <div className={`image-text-2 ${getTextColorClass(ordinal.rarityrank)}`}>{ordinal.rarityrank}</div>
                            </div>
                            <div className="modal-right">
                                <div className="modal-content-right">
                                    <div className="modal-header">
                                        <div className="modal-header-flex">
                                            <div className="modal-header-left">
                                                <div className="modal-header-text">
                                                    {ordinal.collection}
                                                </div>
                                                <div className="modal-id">
                                                    #{ordinal.id}
                                                </div>
                                            </div>
                                            <div className="modal-header-left">
                                                <div className="modal-header-text">
                                                    Inscription
                                                </div>
                                                <div className="modal-id">
                                                    #{ordinal.inscriptionid}
                                                </div>
                                            </div>
                                            <div className="modal-header-right">
                                                <a href={ordinal.link} target="_blank" rel="noreferrer">
                                                    <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="none"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <g fill="#000000"> <path d="M8 3a5 5 0 100 10A5 5 0 008 3z"></path> <path fillRule="evenodd" d="M0 8a8 8 0 1116 0A8 8 0 010 8zm8-6.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13z" clipRule="evenodd"></path> </g> </g></svg>
                                                </a>
                                                <a href={ordinal.magiclink} target="_blank" rel="noreferrer">
                                                    <img className="magic-eden-logo" src="/MELOGO.png" alt="Logo" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-right-traits">
                                        {ordinal.attributes.map((attribute) => (
                                            <li key={attribute.trait_type} className="attribute-item">
                                                <div class="modal-svg-icon">{renderSVG(attribute.trait_type)}</div>
                                                <div className="modal-trait-info">
                                                    <span className="trait-type">{attribute.trait_type}</span>
                                                    <span className="trait-value">{attribute.value}</span>
                                                </div>
                                            </li>
                                        ))}
                                    </div>
                                    <div className='download-flex'>
                                    {showDownloadButton && (
                                        <button className='download-button' onClick={downloadBear}>
                                            <span className='button-text'>{buttonText.bear}</span>
                                            <span className='buttongap'></span>
                                            <svg className="button-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <svg viewBox="0 0 24 24" fill="#ffffff" stroke="#ffffff">
                                                    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                                    <g id="SVGRepo_iconCarrier">
                                                        <g id="Complete">
                                                            <g id="download">
                                                                <g>
                                                                    <path d="M3,12.3v7a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-7" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                    <g>
                                                                        <polyline data-name="Right" fill="none" id="Right-2" points="7.9 12.3 12 16.3 16.1 12.3" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                        <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="12" x2="12" y1="2.7" y2="14.2" />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </svg>
                                        </button>
                                        )}
                                        <button className='download-button' onClick={downloadImage}>
                                            <span className='button-text'>{buttonText.card}</span>
                                            <span className='buttongap'></span>
                                            <svg className="button-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <svg viewBox="0 0 24 24" fill="#ffffff" stroke="#ffffff">
                                                    <g id="SVGRepo_bgCarrier" strokeWidth="0" />
                                                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
                                                    <g id="SVGRepo_iconCarrier">
                                                        <g id="Complete">
                                                            <g id="download">
                                                                <g>
                                                                    <path d="M3,12.3v7a2,2,0,0,0,2,2H19a2,2,0,0,0,2-2v-7" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                    <g>
                                                                        <polyline data-name="Right" fill="none" id="Right-2" points="7.9 12.3 12 16.3 16.1 12.3" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                                        <line fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" x1="12" x2="12" y1="2.7" y2="14.2" />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Ordinal;
