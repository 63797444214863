function renderSVG(traitType) {
    switch (traitType) {
        case 'Backgrounds':
            return (
                <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                    <g id="SVGRepo_iconCarrier">
                        <rect width="48" height="48" fill="white" fill-opacity="0.01"></rect>
                        <path fillRule="evenodd" clipRule="evenodd" d="M37 37C39.2091 37 41 35.2091 41 33C41 31.5272 39.6667 29.5272 37 27C34.3333 29.5272 33 31.5272 33 33C33 35.2091 34.7909 37 37 37Z" fill="#000000"></path>
                        <path d="M20.8535 5.50439L24.389 9.03993" stroke="#000000" strokeWidth="4" strokeLinecap="round"></path>
                        <path d="M23.6818 8.33281L8.12549 23.8892L19.4392 35.2029L34.9955 19.6465L23.6818 8.33281Z" stroke="#000000" strokeWidth="4" strokeLinejoin="round"></path>
                        <path d="M12 20.0732L28.961 25.6496" stroke="#000000" strokeWidth="4" strokeLinecap="round"></path>
                        <path d="M4 43H44" stroke="#000000" strokeWidth="4" strokeLinecap="round"></path>
                    </g>
                </svg>
            );
        case 'Base':
            return (
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><circle fill="none" stroke="#000000" strokeMiterlimit="10" strokeWidth="32" cx="256" cy="56" r="40"></circle><path fill="none" stroke="#000000" strokeMiterlimit="10" strokeWidth="32" d="M199.3,295.62h0l-30.4,172.2a24,24,0,0,0,19.5,27.8,23.76,23.76,0,0,0,27.6-19.5l21-119.9v.2s5.2-32.5,17.5-32.5h3.1c12.5,0,17.5,32.5,17.5,32.5v-.1l21,119.9a23.92,23.92,0,1,0,47.1-8.4l-30.4-172.2-4.9-29.7c-2.9-18.1-4.2-47.6.5-59.7,4-10.4,14.13-14.2,23.2-14.2H424a24,24,0,0,0,0-48H88a24,24,0,0,0,0,48h92.5c9.23,0,19.2,3.8,23.2,14.2,4.7,12.1,3.4,41.6.5,59.7Z"></path></g></svg>
            );
        case 'Clothes':
            return (
                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>clothes</title> <g id="Layer_2" data-name="Layer 2"> <g id="invisible_box" data-name="invisible box"> <rect width="48" height="48" fill="none"></rect> </g> <g id="icons_Q2" data-name="icons Q2"> <path d="M45.2,12.9,37.1,4H10.9L2.8,12.9a3,3,0,0,0,.1,4.2l7.5,7.5V44H37.7V24.6l7.5-7.5A3,3,0,0,0,45.2,12.9Zm-8.4,6.8-3.1-2.9V40H14.3V16.8l-3.1,2.9L6.4,14.9,12.7,8h7.4a4,4,0,0,0,7.8,0h7.4l6.3,6.9Z"></path> </g> </g> </g></svg>
            );
        case 'Eyes':
            return (
                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9ZM11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12Z" fill="#000000"></path> <path fillRule="evenodd" clipRule="evenodd" d="M21.83 11.2807C19.542 7.15186 15.8122 5 12 5C8.18777 5 4.45796 7.15186 2.17003 11.2807C1.94637 11.6844 1.94361 12.1821 2.16029 12.5876C4.41183 16.8013 8.1628 19 12 19C15.8372 19 19.5882 16.8013 21.8397 12.5876C22.0564 12.1821 22.0536 11.6844 21.83 11.2807ZM12 17C9.06097 17 6.04052 15.3724 4.09173 11.9487C6.06862 8.59614 9.07319 7 12 7C14.9268 7 17.9314 8.59614 19.9083 11.9487C17.9595 15.3724 14.939 17 12 17Z" fill="#000000"></path> </g></svg>
            );
        case 'Glasses':
            return (
                <svg fill="#000000" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m15.86 9.16-1.43-5.31a1.88 1.88 0 0 0-1.81-1.37h-.44v1.26h.44a.63.63 0 0 1 .6.46L14 7.06a3.9 3.9 0 0 0-1.71-.39 3.64 3.64 0 0 0-3.7 3H7.42a3.64 3.64 0 0 0-3.7-3A3.87 3.87 0 0 0 2 7.06l.78-2.86a.63.63 0 0 1 .6-.46h.44V2.48h-.44a1.88 1.88 0 0 0-1.81 1.37L.14 9.16a3.19 3.19 0 0 0-.14.94 3.59 3.59 0 0 0 3.72 3.42 3.71 3.71 0 0 0 3.62-2.59h1.32a3.71 3.71 0 0 0 3.62 2.59A3.59 3.59 0 0 0 16 10.1a3.19 3.19 0 0 0-.14-.94zm-12.14 3.1a2.33 2.33 0 0 1-2.46-2.16 2.33 2.33 0 0 1 2.46-2.17 2.34 2.34 0 0 1 2.47 2.17 2.34 2.34 0 0 1-2.47 2.16zm8.56 0a2.34 2.34 0 0 1-2.47-2.16 2.34 2.34 0 0 1 2.47-2.17 2.33 2.33 0 0 1 2.46 2.17 2.33 2.33 0 0 1-2.46 2.16z"></path></g></svg>
            );
        case 'Face':
            return (
                <svg fill="#000000" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="m15.86 9.16-1.43-5.31a1.88 1.88 0 0 0-1.81-1.37h-.44v1.26h.44a.63.63 0 0 1 .6.46L14 7.06a3.9 3.9 0 0 0-1.71-.39 3.64 3.64 0 0 0-3.7 3H7.42a3.64 3.64 0 0 0-3.7-3A3.87 3.87 0 0 0 2 7.06l.78-2.86a.63.63 0 0 1 .6-.46h.44V2.48h-.44a1.88 1.88 0 0 0-1.81 1.37L.14 9.16a3.19 3.19 0 0 0-.14.94 3.59 3.59 0 0 0 3.72 3.42 3.71 3.71 0 0 0 3.62-2.59h1.32a3.71 3.71 0 0 0 3.62 2.59A3.59 3.59 0 0 0 16 10.1a3.19 3.19 0 0 0-.14-.94zm-12.14 3.1a2.33 2.33 0 0 1-2.46-2.16 2.33 2.33 0 0 1 2.46-2.17 2.34 2.34 0 0 1 2.47 2.17 2.34 2.34 0 0 1-2.47 2.16zm8.56 0a2.34 2.34 0 0 1-2.47-2.16 2.34 2.34 0 0 1 2.47-2.17 2.33 2.33 0 0 1 2.46 2.17 2.33 2.33 0 0 1-2.46 2.16z"></path></g></svg>
            );
        case 'Head':
            return (
                <svg fill="#000000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M12,22h2.787A7.211,7.211,0,0,0,22,14.787V12a9.953,9.953,0,0,0-.6-3.377L21.394,8.6A10,10,0,1,0,12,22Zm7.737-12A7.953,7.953,0,0,1,20,12v2.787c0,.072-.01.142-.013.213H13.858a1,1,0,0,1-.961-.725l-.857-3A1,1,0,0,1,13,10ZM12,4a8,8,0,0,1,6.918,4H13a3,3,0,0,0-2.884,3.824l.857,3A3.013,3.013,0,0,0,13.858,17H19.5a5.182,5.182,0,0,1-4.71,3H12A8,8,0,0,1,12,4Z"></path></g></svg>
            );
        case 'Mouth':
            return (
                <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="48" height="48" fill="white" fill-opacity="0.01"></rect> <path d="M4 24C4 24 10 15 14 15C18 15 22 17 24 17C26 17 30 15 34 15C38 15 44 24 44 24C44 24 34 34 24 34C14 34 4 24 4 24Z" stroke="#000000" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M4 24H44" stroke="#000000" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
            );
        case 'Special Bears':
            return (
                <svg fill="#000000" width="185px" height="185px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M31.835 9.233l-4.371-8.358c-0.255-0.487-0.915-0.886-1.464-0.886h-10.060c-0.011-0.001-0.022-0.003-0.033-0.004-0.009 0-0.018 0.003-0.027 0.004h-9.88c-0.55 0-1.211 0.398-1.47 0.883l-4.359 8.197c-0.259 0.486-0.207 1.248 0.113 1.696l15.001 20.911c0.161 0.224 0.375 0.338 0.588 0.338 0.212 0 0.424-0.11 0.587-0.331l15.247-20.758c0.325-0.444 0.383-1.204 0.128-1.691zM29.449 8.988h-5.358l2.146-6.144zM17.979 1.99h6.436l-1.997 5.716zM20.882 8.988h-9.301l4.396-6.316zM9.809 8.034l-2.006-6.044h6.213zM21.273 10.988l-5.376 15.392-5.108-15.392h10.484zM13.654 25.971l-10.748-14.983h5.776zM23.392 10.988h5.787l-11.030 15.018zM5.89 2.575l2.128 6.413h-5.539z"></path> </g></svg>
            );
        default:
            return (
                <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" fill="#000000">
                    {/* ... */}
                </svg>
            );
    }
}

export default renderSVG;