import React from 'react';
import './BEARCUBS.css';

const BEARCUBS = () => {

  return (
    <div className="BEARCUBS-section">
      <h2 className='BEARCUBS-title'>Welcome, Bitcoin Bear Cubs</h2>
      <p className='BEARCUBS-description'>As the Bitcoin Bears held their ground in the digital wilderness, a new wave of vibrancy emerged - the Bitcoin Bear Cubs. These 10,000 pixelated Cubs, inscribed under the one-millionth mark, bring a fresh spark of fun, energy, and meme-filled mischief to our growing community. Drawing inspiration from the steadfast Bitcoin Bears, our Bear Cubs are ready to make their own mark on the blockchain, continuing the legacy while infusing it with their youthful exuberance. Join us as we embark on this exciting new chapter in our Bitcoin journey.</p>
      <a className='bearcubs-item' href="https://magiceden.io/ordinals/marketplace/bbc" target="_blank" rel="noopener noreferrer">
                    <button>
                        BUY A CUB
                    </button>
                </a>
    </div>
  );
};

export default BEARCUBS;
