import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import MobileMenu from "../MobileMenu/MobileMenu";
import "./Header.css";
import "../MobileMenu/MobileMenu.css";
import DropdownMenu from './DropdownMenu';

const Header = () => {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const signImageUrl = "/bbclogo1.png";

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleCloseMenu = () => {
        setIsOpen(false);
    };

    const isCurrentPath = (path, location) => {
        return location.pathname === path;
    };


    return (
        <header className="App-header">
            <Link to="/">
                <img src={signImageUrl} alt="Bitcoin Bears Logo" className="bear-logo-image" />
            </Link>
            <button className="hamburger" onClick={toggleMenu}>
                {isOpen ? <FaTimes /> : <FaBars />}
            </button>
            {isOpen && <MobileMenu onClose={handleCloseMenu} toggleMenu={toggleMenu} />}
            <div className="button-container">
                <Link to="/">
                <div className="home-button-backdrop">
                    <button className={isCurrentPath("/", location) ? "active" : ""}>Home</button>
                    </div>
                </Link>
                <span className="button-gap"></span>
                <Link to="/den">
                <div className="button-backdrop">
                    <button className={isCurrentPath("/den", location) ? "active" : ""}>The Den</button>
                    </div>
                </Link>
                <span className="button-gap"></span>
                <Link to="/gallery">
                <div className="button-backdrop">
                    <button className={isCurrentPath("/gallery", location) ? "active" : ""}>Gallery</button>
                    </div>
                </Link>
                <span className="button-gap"></span>
                <DropdownMenu />
                <span className="button-gap"></span>
                <a href="https://ordinals.com/content/092bdecfa9e387566dc723981cc549ddde581a6f59181254fe7b823b37580c1di0" target="_blank" rel="noopener noreferrer">
                <div className="button-backdrop">
                    <button className="button-svg-item">
                        <svg fill="#000000" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <title>paper</title> <path d="M0 32l4-4 4 4 4-4 4 4 4-4 4 4 4-4 4 4v-25.984q0-2.496-1.76-4.256t-4.224-1.76h-20q-2.496 0-4.256 1.76t-1.76 4.256v25.984zM4 22.016v-16q0-0.832 0.576-1.408t1.44-0.608h20q0.8 0 1.408 0.608t0.576 1.408v16l-4 4-4-4-4 4-4-4-4 4zM8 18.016h16v-2.016h-16v2.016zM8 14.016h16v-2.016h-16v2.016zM8 10.016h16v-2.016h-16v2.016z"></path> </g></svg>
                    </button>
                    </div>
                </a>
                <span className="button-gap"></span>
                <a href="https://discord.com/invite/bitcoinbears" target="_blank" rel="noopener noreferrer">
                <div className="button-backdrop">
                    <button className="discord-svg-item">
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M18.59 5.88997C17.36 5.31997 16.05 4.89997 14.67 4.65997C14.5 4.95997 14.3 5.36997 14.17 5.69997C12.71 5.47997 11.26 5.47997 9.83001 5.69997C9.69001 5.36997 9.49001 4.95997 9.32001 4.65997C7.94001 4.89997 6.63001 5.31997 5.40001 5.88997C2.92001 9.62997 2.25001 13.28 2.58001 16.87C4.23001 18.1 5.82001 18.84 7.39001 19.33C7.78001 18.8 8.12001 18.23 8.42001 17.64C7.85001 17.43 7.31001 17.16 6.80001 16.85C6.94001 16.75 7.07001 16.64 7.20001 16.54C10.33 18 13.72 18 16.81 16.54C16.94 16.65 17.07 16.75 17.21 16.85C16.7 17.16 16.15 17.42 15.59 17.64C15.89 18.23 16.23 18.8 16.62 19.33C18.19 18.84 19.79 18.1 21.43 16.87C21.82 12.7 20.76 9.08997 18.61 5.88997H18.59ZM8.84001 14.67C7.90001 14.67 7.13001 13.8 7.13001 12.73C7.13001 11.66 7.88001 10.79 8.84001 10.79C9.80001 10.79 10.56 11.66 10.55 12.73C10.55 13.79 9.80001 14.67 8.84001 14.67ZM15.15 14.67C14.21 14.67 13.44 13.8 13.44 12.73C13.44 11.66 14.19 10.79 15.15 10.79C16.11 10.79 16.87 11.66 16.86 12.73C16.86 13.79 16.11 14.67 15.15 14.67Z" fill="#000000"></path> </g></svg>
                    </button>
                    </div>
                </a>
                <span className="button-gap"></span>
                <a href="https://twitter.com/bitcoinbearsord" target="_blank" rel="noopener noreferrer">
                <div className="button-backdrop">
                    <button className="button-svg-item">
                        <svg fill="#000000" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M1920 311.856c-70.701 33.769-146.598 56.47-226.221 66.86 81.317-52.517 143.774-135.529 173.252-234.691-76.236 48.678-160.716 84.028-250.391 103.002-71.718-82.56-174.268-134.06-287.435-134.06-217.75 0-394.165 189.966-394.165 424.206 0 33.318 3.614 65.619 10.165 96.678C617.9 616.119 327.304 447.385 133.045 190.67c-33.77 62.57-53.309 135.53-53.309 213.233 0 147.162 91.031 276.818 196.744 353.054-64.602-2.26-157.101-21.46-157.101-53.309v5.648c0 205.327 114.41 376.658 294.55 415.849-32.978 9.487-78.38 14.795-114.409 14.795-25.412 0-55.454-2.71-79.624-7.793 50.26 168.509 193.13 291.163 365.478 294.777-134.852 113.506-306.07 181.383-490.616 181.383-31.85 0-64.038-2.033-94.758-5.873 174.494 120.17 381.176 190.532 603.67 190.532 724.97 0 1121.055-646.136 1121.055-1206.55 0-18.41-.452-36.932-1.356-55.116 77.026-59.746 143.887-134.4 196.631-219.444" fillRule="evenodd"></path> </g></svg>
                    </button>
                    </div>
                </a>
                <span className="button-gap"></span>
                <a href="https://ordinals.com/inscription/da3c198adf885998c90cdc75264d4ad4c12ebc8786bd981d95e5b149ab126398i0" target="_blank" rel="noopener noreferrer">
                </a>
            </div>
        </header>
    );
};

export default Header;
