import React, { useEffect, useState } from 'react';
import './Den.css';
import Team from '../Team/Team';
import BEARCUBS from '../BEARCUBS/BEARCUBS';
import OGBEARS from '../OGBEARS/OGBEARS';

function debounce(func, wait = 20) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }
  

const Den = () => {
  const bearUrl = "/286.png";
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (window.pageYOffset > window.innerHeight && window.pageYOffset < (window.innerHeight * 3) - 1) {  
        setSticky(true);
      } else {
        setSticky(false);
      }
    });

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    }
  }, []);


  return (
    <div className="entire-page">
      <div className="den-background">
        <OGBEARS />
      </div>
      <div className="second-page">
        <img src={bearUrl} alt="Bitcoin Bears Logo" className={`bear-image ${isSticky ? "sticky" : ""}`} />
        <BEARCUBS />
      </div>
      <div className="third-page">
        <Team />
      </div>
    </div>
  );
};

export default Den;
