import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

const DropdownMenu = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);
    const location = useLocation();

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const isCurrentPath = (path) => {
        return location.pathname === path;
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <div className={`dropdown-container ${dropdownOpen ? "dropdown-open" : ""}`} ref={dropdownRef}>
            <button className="dropdown-button" onClick={toggleDropdown}>
                <span>More</span>
                <span className='buttongap'></span>
                <svg viewBox="0 0 1024 1024" class="icon" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M903.232 256l56.768 50.432L512 768 64 306.432 120.768 256 512 659.072z" fill="#ffffff"></path></g></svg>
            </button>
            {dropdownOpen && (
                <div className="dropdown-content">
                    <Link to="/holders">
                        <button className={isCurrentPath("/holders") ? "active" : ""}>Holders</button>
                    </Link>
                    <Link to="/honeyfactory">
                        <button className={isCurrentPath("/honeyfactory") ? "active" : ""}>Honey Factory</button>
                    </Link>
                    <a href="https://magiceden.io/ordinals/marketplace/bitcoin-bears" target="_blank" rel="noopener noreferrer">
                        <button>Buy Bears</button>
                    </a>
                    <a href="https://magiceden.io/ordinals/marketplace/bbc" target="_blank" rel="noopener noreferrer">
                        <button>Buy Cubs</button>
                    </a>
                </div>
            )}
        </div>
    );
};

export default DropdownMenu;
