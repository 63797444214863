import { BrowserRouter as Router } from 'react-router-dom';
import { Analytics } from '@vercel/analytics/react';
import Layout from './components/Layout/Layout';

function App() {
  return (
    <Router>
      <Layout />
      <Analytics />
    </Router>
  );
}

export default App;
