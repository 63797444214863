import React, { useEffect, useState} from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Gallery from '../Gallery/Gallery';
import Den from '../Den/Den';
import Holders from '../Holders/Holders';
import Header from '../Header/Header';
import ArtG from '../ArtG/ArtG';
import './Layout.css';

const Layout = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState(null);

  useEffect(() => {
    // Update the previous location when the location changes
    setPrevLocation(location);
  }, [location]);

  useEffect(() => {
    const mainContent = document.querySelector('.main');
    mainContent.classList.add('fade-in');

    const removeFadeInClass = () => mainContent.classList.remove('fade-in');
    mainContent.addEventListener('animationend', removeFadeInClass);

    return () => mainContent.removeEventListener('animationend', removeFadeInClass);
  }, [location]);

  return (
    <div className={`bg-gallery ${location.pathname === '/gallery' ? 'gallery-background' : ''}`}>
      <div className={`layout ${location.pathname === '/' ? 'home-background' : ''}`}>
        <div className="main-header">
          <Header />
        </div>
        <main className="main">
          <TransitionGroup>
            <CSSTransition key={prevLocation?.key || location.key} timeout={300} classNames="fade">
              <Routes location={location}>
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/den" element={<Den />} />
                <Route path="/holders" element={<Holders />} />
                <Route path="/honeyfactory" element={<ArtG />} />
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        </main>
      </div>
    </div>
  );
};

export default Layout;
