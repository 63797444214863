import React from 'react';
import './OGBEARS.css';

const OGBEARS = () => {

  return (
    <div className="OGBEARS-section">
      <h2 className='OGBEARS-title'>The Bitcoin Bears Story</h2>
      <p className='OGBEARS-description'>In the early days of the blockchain, the Bitcoin Bears made their mark. A collection of 100 unique digital bears, these pioneers were inscribed under the 6000th mark on the Bitcoin blockchain. Each bear, a symbol of our early strides in the ever-evolving world of cryptocurrency, signified the start of an exciting adventure into the uncharted lands of digital art and Bitcoin collectibles.</p>
      <a className='ogbear-item' href="https://magiceden.io/ordinals/marketplace/bitcoin-bears" target="_blank" rel="noopener noreferrer">
                    <button>
                        Buy an OG bear
                    </button>
                </a>
      </div>
  );
};

export default OGBEARS;
