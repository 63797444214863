import React, { useState, useEffect, useRef, useCallback } from 'react';
import { saveAs } from 'file-saver';
import 'canvas-toBlob';
import "./ArtG.css";

const directories = ['BG', 'BGTEXT', 'Chat', 'Blue', 'Blue2', 'Blue3', 'Brown', 'Brown2', 'Darkgreen', 'Green', 'Gold', 'Pink', 'Purple', 'Panda', 'White', 'Extras']; // Add all 12 folders here
const directoryImages = {
  'BG': 'bear/BG/4.png',
  'BGTEXT': 'bear/BGTEXT/2.png',
  'Chat': 'bear/Chat/2.png',
  'Blue': 'bear/Thumbnails/Blue.png',
  'Blue2': 'bear/Thumbnails/Blue2.png',
  'Blue3': 'bear/Thumbnails/Blue3.png',
  'Brown': 'bear/Thumbnails/Brown.png',
  'Brown2': 'bear/Thumbnails/Brown2.png',
  'Darkgreen': 'bear/Thumbnails/Darkgreen.png',
  'Green': 'bear/Thumbnails/Green.png',
  'Gold': 'bear/Thumbnails/Gold.png',
  'Pink': 'bear/Thumbnails/Pink.png',
  'Purple': 'bear/Thumbnails/Purple.png',
  'Panda': 'bear/Thumbnails/Panda.png',
  'White': 'bear/Thumbnails/White.png',
  'Extras': 'bear/Extras/2.png',
}

const directoryImageCounts = {
  'BG': 5,
  'BGTEXT': 6,
  'Chat': 9,
  'Blue': 5,
  'Blue2': 5,
  'Blue3': 5,
  'Brown': 5,
  'Brown2': 5,
  'Darkgreen': 5,
  'Green': 5,
  'Gold': 5,
  'Pink': 5,
  'Purple': 5,
  'Panda': 5,
  'White': 5,
  'Extras': 6,
};

const ArtG = () => {
  const [userImage, setUserImage] = useState(null);
  const [selectedDirectory, setSelectedDirectory] = useState(null);
  const [selectedStockImage, setSelectedStockImage] = useState(null);
  const [stockImages, setStockImages] = useState([]);
  const canvasRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const overlayCanvasRef = useRef(null);
  const behindDirectories = ['BG', 'BGTEXT'];
  const [behindStockImage, setBehindStockImage] = useState(null);
  const [bgColor, setBgColor] = useState('rgba(0, 0, 0, 0)');
  const [selectedChatStockImage, setSelectedChatStockImage] = useState(null);
  const [selectedBgTextStockImage, setSelectedBgTextStockImage] = useState(null);


  useEffect(() => {
    if (selectedDirectory) {
      const images = [];
      for (let i = 1; i <= directoryImageCounts[selectedDirectory]; i++) {
        images.push(`bear/${selectedDirectory}/${i}.png`);
      }
      setStockImages(images);
    }
  }, [selectedDirectory]);


  const onImageUpload = async (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async (event) => {
        const blob = new Blob([event.target.result]);
        const formData = new FormData();
        formData.append('image_file', blob);

        try {
          const response = await fetch('https://api.remove.bg/v1.0/removebg', {
            method: 'POST',
            headers: {
              'X-Api-Key': 'mfJF1wWb2DYidXGnVfLZy6ef'
            },
            body: formData
          });

          if (response.ok) {
            const blob = await response.blob();
            const urlCreator = window.URL || window.webkitURL;
            const imageUrl = urlCreator.createObjectURL(blob);
            setUserImage(imageUrl);
          } else {
            console.error('Response was not ok:', response);
          }
        } catch (error) {
          console.error('Error while calling remove.bg API:', error);
        }
      };
    }
  };

  const handleStockImageSelect = (image, directory) => {
    if (directory === 'BGTEXT') {
      setSelectedBgTextStockImage(image);
    } else if (directory === 'Chat') {
      setSelectedChatStockImage(image);
    } else {
      if (behindDirectories.includes(directory)) {
        setBehindStockImage(image);
      } else {
        setSelectedStockImage(image);
        if (isBgTextImage(behindStockImage) && directory !== 'BGTEXT') {
          setBehindStockImage(null);
        }
      }
    }
  };  
  
  const handleDownload = () => {
    if (canvasRef.current) {
      const mergedCanvas = document.createElement('canvas');
      mergedCanvas.width = 600;
      mergedCanvas.height = 600;
      const mergedCtx = mergedCanvas.getContext('2d');

      mergedCtx.drawImage(previewCanvasRef.current, 0, 0, 600, 600);
      mergedCtx.drawImage(overlayCanvasRef.current, 0, 0, 600, 600);

      mergedCanvas.toBlob((blob) => {
        saveAs(blob, 'my-image.png');
      });
    }
  };

  const isBgTextImage = (imgSrc) => {
    return imgSrc && imgSrc.includes('BGTEXT');
  };
  
  const renderCanvas = useCallback(
    (
      canvas,
      imgSrc0,
      imgSrc1,
      imgSrc2,
      chatImgSrc,
      bgTextImgSrc,
      width,
      height
    ) => {
      if (canvas) {
        // adjust the canvas height for BGTEXT images
        canvas.height = isBgTextImage(imgSrc0) ? height + 100 : height;
        canvas.width = width;
        const ctx = canvas.getContext('2d');
  
        const img0 = new Image();
        const img1 = new Image();
        const img2 = new Image();
        const chatImg = new Image();
        const bgTextImg = new Image();
  
        img1.onload = () => {
          ctx.clearRect(0, 0, width, height);
          ctx.fillStyle = bgColor;
          ctx.fillRect(0, 0, canvas.width, canvas.height);
  
          if (imgSrc0) {
            img0.onload = () => {
              ctx.imageSmoothingEnabled = false;
              if (isBgTextImage(imgSrc0)) {
                ctx.drawImage(img0, 0, 0, width, height - 100);
              } else {
                ctx.drawImage(img0, 0, 0, width, height);
              }
  
              if (bgTextImgSrc) {
                bgTextImg.onload = () => {
                  ctx.drawImage(bgTextImg, 0, 0, width, height);
                  ctx.drawImage(img1, 0, 0, width, height);
  
                  if (imgSrc2) {
                    img2.onload = () => {
                      ctx.drawImage(img2, 0, 0, width, height);
  
                      if (chatImgSrc) {
                        chatImg.onload = () => {
                          ctx.drawImage(chatImg, 0, 0, width, height);
                        };
                        chatImg.src = chatImgSrc;
                      }
                    };
                    img2.src = imgSrc2;
                  } else if (chatImgSrc) {
                    chatImg.onload = () => {
                      ctx.drawImage(chatImg, 0, 0, width, height);
                    };
                    chatImg.src = chatImgSrc;
                  }
                };
                bgTextImg.src = bgTextImgSrc;
              } else {
                ctx.drawImage(img1, 0, 0, width, height);
  
                if (imgSrc2) {
                  img2.onload = () => {
                    ctx.drawImage(img2, 0, 0, width, height);
  
                    if (chatImgSrc) {
                      chatImg.onload = () => {
                        ctx.drawImage(chatImg, 0, 0, width, height);
                      };
                      chatImg.src = chatImgSrc;
                    }
                  };
                  img2.src = imgSrc2;
                } else if (chatImgSrc) {
                  chatImg.onload = () => {
                    ctx.drawImage(chatImg, 0, 0, width, height);
                  };
                  chatImg.src = chatImgSrc;
                }
              }
            };
            img0.src = imgSrc0;
          } else {
            ctx.imageSmoothingEnabled = false;
            ctx.drawImage(img1, 0, 0, width, height);
  
            if (bgTextImgSrc) {
              bgTextImg.onload = () => {
                ctx.drawImage(bgTextImg, 0, 0, width, height);
                ctx.drawImage(img1, 0, 0, width, height);
  
                if (imgSrc2) {
                  img2.onload = () => {
                    ctx.drawImage(img2, 0, 0, width, height);
  
                    if (chatImgSrc) {
                      chatImg.onload = () => {
                        ctx.drawImage(chatImg, 0, 0, width, height);
                      };
                      chatImg.src = chatImgSrc;
                    }
                  };
                  img2.src = imgSrc2;
                } else if (chatImgSrc) {
                  chatImg.onload = () => {
                    ctx.drawImage(chatImg, 0, 0, width, height);
                  };
                  chatImg.src = chatImgSrc;
                }
              };
              bgTextImg.src = bgTextImgSrc;
            } else {
              if (imgSrc2) {
                img2.onload = () => {
                  ctx.drawImage(img2, 0, 0, width, height);
  
                  if (chatImgSrc) {
                    chatImg.onload = () => {
                      ctx.drawImage(chatImg, 0, 0, width, height);
                    };
                    chatImg.src = chatImgSrc;
                  }
                };
                img2.src = imgSrc2;
              } else if (chatImgSrc) {
                chatImg.onload = () => {
                  ctx.drawImage(chatImg, 0, 0, width, height);
                };
                chatImg.src = chatImgSrc;
              }
            }
          }
        };
        img1.src = imgSrc1;
      }
    },
    [bgColor]
  );
  

  useEffect(() => {
    if (userImage) {
      renderCanvas(
        previewCanvasRef.current,
        behindStockImage,
        userImage,
        selectedStockImage,
        selectedChatStockImage,
        selectedBgTextStockImage,
        600,
        600
      );
    }
  }, [
    userImage,
    selectedStockImage,
    behindStockImage,
    selectedChatStockImage,
    selectedBgTextStockImage,
    renderCanvas
  ]);
  
  useEffect(() => {
    if (selectedStockImage || selectedChatStockImage || selectedBgTextStockImage) {
      renderCanvas(
        overlayCanvasRef.current,
        null,
        selectedStockImage,
        null,
        selectedChatStockImage,
        selectedBgTextStockImage,
        192,
        192
      );
    }
  }, [
    selectedStockImage,
    selectedChatStockImage,
    selectedBgTextStockImage,
    renderCanvas
  ]);
  
  useEffect(() => {
    if (
      userImage &&
      (selectedStockImage || selectedChatStockImage || selectedBgTextStockImage)
    ) {
      if (canvasRef.current && overlayCanvasRef.current) {
        const ctx = canvasRef.current.getContext('2d');
        ctx.clearRect(0, 0, 600, 600);
        ctx.drawImage(previewCanvasRef.current, 0, 0, 600, 600);
        ctx.drawImage(overlayCanvasRef.current, 0, 0, 600, 600);
      }
    }
  }, [
    userImage,
    selectedStockImage,
    selectedChatStockImage,
    selectedBgTextStockImage,
    behindStockImage,
    renderCanvas
  ]);
  
  
  return (
    <div className="entire-page">
      <div className="artg-background">
        {userImage ? (
          <div className="art-image-container">
            <canvas ref={previewCanvasRef} className="preview-canvas" />
            <div className="options-container">
              <h2>Edit Background</h2>
              {selectedDirectory ? (
                <>
                  <button
                    className="art-download-button"
                    onClick={() => setSelectedDirectory(null)}
                  >
                    Back
                  </button>
                  <div className="stock-images-container">
                    {stockImages.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Stock ${index + 1}`}
                        className={`stock-image ${selectedStockImage === image ? 'selected' : ''}`}
                        onClick={() => handleStockImageSelect(image, selectedDirectory)}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <div className="stock-images-container">
                  {directories.map((directory, index) => (
                    <img
                      key={index}
                      src={directoryImages[directory]}
                      alt={directory}
                      className="stock-image"
                      onClick={() => setSelectedDirectory(directory)}
                    />
                  ))}
                </div>
              )}
              <div className='flex-combine'>
                <div className="color-picker-container">
                <div className='flex-1'>
                  <input
                    className='color-picker'
                    type="color"
                    id="bg-color-picker"
                    value={bgColor}
                    onChange={(event) => setBgColor(event.target.value)}
                  />
                  <button
                    className="color-switch"
                    style={{ backgroundColor: '#fe4b01' }}
                    onClick={() => setBgColor('#fe4b01')}
                  ></button>
                  </div>
                  <button className='art-transparent-button' onClick={() => setBgColor('rgba(0, 0, 0, 0)')}>Transparent</button>
                </div>
                <div className='flex-2'>
                  <div className="reupload-container">
                    <button className="reupload-button" onClick={() => setUserImage(null)}>
                      Reupload
                    </button>
                  </div>
                  <button className="art-download-button" onClick={handleDownload}>
                    Download
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
  <div className="upload-gif">
    <img src="/bear/1.gif" alt="Upload GIF"/>
  </div>
          <div className="upload-container">
            <label htmlFor="upload-input" className="upload-label">
              <span className="upload-icon">&#x21E1;</span> Upload Image
            </label>
            <input
              id="upload-input"
              type="file"
              accept="image/*"
              onChange={onImageUpload}
              className="upload-input"
            />
          </div>
          </div>
        )}
        <canvas ref={canvasRef} style={{ display: 'none' }} />
        <canvas ref={overlayCanvasRef} style={{ display: 'none' }} />
      </div>
    </div>
  );
};

export default ArtG;