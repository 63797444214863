import React, { useState, useEffect, useCallback } from 'react';
import Ordinal from './Ordinal';
import ordinalsData from './../../data/ordinals.json';
import './Gallery.css';
import './Filter.css';
import './MobileGallery.css';
import renderIcon from './SVGICONS/iconRenderer';
import PlusMinusButton from './SVGICONS/PlusMinusButton';
import ordinalBearsData from './../../data/ordinalsbears.json';

function Gallery() {
    const [ordinals, setOrdinals] = useState([]);
    const [dataSource, setDataSource] = useState(ordinalsData);
    const [activeDataSource, setActiveDataSource] = useState('original');
    const [allOrdinals, setAllOrdinals] = useState([]);
    const [lastAppliedFilter, setLastAppliedFilter] = useState(null);
    // eslint-disable-next-line no-unused-vars
    const [showDownloadButton, setShowDownloadButton] = useState(true);
    const [filters, setFilters] = useState({
        Backgrounds: [],
        Base: [],
        Clothes: [],
        Eyes: [],
        Glasses: [],
        Head: [],
        Face: [],
        Mouth: [],
        'Special Bears': []
    });
    const [expanded, setExpanded] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsToShow, setItemsToShow] = useState(50);
    const [searchTerms, setSearchTerms] = useState({
        Backgrounds: '',
        Base: '',
        Clothes: '',
        Eyes: '',
        Glasses: '',
        Head: '',
        Face: '',
        Mouth: '',
        'Special Bears': ''
    });
    const defaultTraitTypesOrder = [
        'Backgrounds', 'Base', 'Clothes', 'Eyes', 'Glasses', 'Head', 'Face', 'Mouth', 'Special Bears'
    ];


    useEffect(() => {
        setOrdinals(dataSource.ordinals);
        setAllOrdinals(dataSource.ordinals);
    }, [dataSource.ordinals]);


    const switchToAnotherDataSource = () => {
        setDataSource(ordinalBearsData);
        setActiveDataSource("another");
        setShowDownloadButton(false); // Hide the "Download Bear" button
      };

    const switchToOriginalDataSource = () => {
        setDataSource(ordinalsData);
        setActiveDataSource('original');
    };


    const handleFilterChange = (e) => {
        const { name, value, checked } = e.target;
        setFilters((prevFilters) => {
            const newFilters = {
                ...prevFilters,
                [name]: checked
                    ? [...prevFilters[name], value]
                    : prevFilters[name].filter((v) => v !== value),
            };
            return newFilters;
        });

        setAppliedFilters((prevAppliedFilters) => {
            const filterString = `${name}: ${value}`;
            if (checked) {
                return [...prevAppliedFilters, filterString];
            } else {
                return prevAppliedFilters.filter((filter) => filter !== filterString);
            }
        });
    };

    const removeFilter = (filterString) => {
        const [name, value] = filterString.split(': ');
        setFilters((prevFilters) => {
            const newFilters = {
                ...prevFilters,
                [name]: prevFilters[name].filter((v) => v !== value),
            };
            return newFilters;
        });

        setAppliedFilters((prevAppliedFilters) => {
            return prevAppliedFilters.filter((filter) => filter !== filterString);
        });
    };

    function getTraitCount(traitType, value) {
        return ordinals.reduce((accumulator, ordinal) => {
            const attributes = ordinal.attributes.filter(attribute => attribute.trait_type === traitType && attribute.value === value);
            if (attributes.length > 0) {
                return accumulator + 1;
            }
            return accumulator;
        }, 0);
    }

    function getSearchTermForTraitType(traitType) {
        return searchTerms[traitType] || '';
    }

    function getUniqueValues(traitType) {
        let uniqueValues = [];
        let counts = {};

        // Count the number of times each value occurs
        ordinals.forEach((ordinal) => {
            const attributes = ordinal.attributes.filter(attribute => attribute.trait_type === traitType);
            if (attributes.length > 0) {
                attributes.forEach(attribute => {
                    const value = attribute.value;
                    if (counts[value]) {
                        counts[value]++;
                    } else {
                        counts[value] = 1;
                    }
                });
            }
        });

        // Sort values by count in descending order
        uniqueValues = Object.keys(counts).sort((a, b) => counts[b] - counts[a]);

        // Filter the unique values based on the search term
        const searchTermForTraitType = getSearchTermForTraitType(traitType);
        uniqueValues = uniqueValues.filter(value =>
            value.toLowerCase().includes(searchTermForTraitType.toLowerCase())
        );


        return uniqueValues;
    }


    function handleExpandCollapse(category) {
        if (expanded.includes(category)) {
            setExpanded(expanded.filter(item => item !== category));
        } else {
            setExpanded([...expanded, category]);
        }
    }

    const filteredOrdinals = ordinals.filter((ordinal) => {
        const attributeValues = ordinal.attributes.map((attribute) => {
            return `${attribute.trait_type.replace(' ', '_')}_${attribute.value}`;
        });
        return (
            (searchTerm === '' || ordinal.id.toString() === searchTerm || ordinal.inscriptionid === searchTerm) &&
            (filters.Backgrounds.length === 0 || filters.Backgrounds.some(value => attributeValues.includes(`Backgrounds_${value}`))) &&
            (filters.Base.length === 0 || filters.Base.some(value => attributeValues.includes(`Base_${value}`))) &&
            (filters.Clothes.length === 0 || filters.Clothes.some(value => attributeValues.includes(`Clothes_${value}`))) &&
            (filters.Eyes.length === 0 || filters.Eyes.some(value => attributeValues.includes(`Eyes_${value}`))) &&
            (filters.Glasses.length === 0 || filters.Glasses.some(value => attributeValues.includes(`Glasses_${value}`))) &&
            (filters.Face.length === 0 || filters.Face.some(value => attributeValues.includes(`Face_${value}`))) &&
            (filters.Head.length === 0 || filters.Head.some(value => attributeValues.includes(`Head_${value}`))) &&
            (filters.Mouth.length === 0 || filters.Mouth.some(value => attributeValues.includes(`Mouth_${value}`))) &&
            (filters['Special Bears'].length === 0 || filters['Special Bears'].some(value => attributeValues.includes(`Special_Bears_${value}`))))
    });

    const [filterVisible, setFilterVisible] = useState(false);

    function handleFilterButtonClick() {
        setFilterVisible(prevFilterVisible => !prevFilterVisible);
        if (!filterVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }

    function handleCloseButtonClick() {
        setFilterVisible(false);
        document.body.style.overflow = 'auto';
    }

    const handleSearchInputChange = (e) => {
        setSearchTerm(e.target.value);
    };


    const handleCategorySearchInputChange = (category, value) => {
        setSearchTerms(prevSearchTerms => ({
            ...prevSearchTerms,
            [category]: value,
        }));
    };

    const handleScroll = useCallback(() => {
        const scrollPosition = window.innerHeight + window.pageYOffset;
        const documentHeight = document.documentElement.offsetHeight;

        if (scrollPosition >= documentHeight - 200) {
            setItemsToShow((itemsToShow) => itemsToShow + 50);
        }
    }, []);



    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    function getUniqueTraitTypes(ordinals) {
        const traitTypesSet = new Set();

        ordinals.forEach((ordinal) => {
            ordinal.attributes.forEach((attribute) => {
                traitTypesSet.add(attribute.trait_type);
            });
        });

        const traitTypes = Array.from(traitTypesSet);

        // sort traitTypes based on defaultTraitTypesOrder
        traitTypes.sort((a, b) => {
            const indexA = defaultTraitTypesOrder.indexOf(a);
            const indexB = defaultTraitTypesOrder.indexOf(b);

            if (indexA === -1) {
                // a is not in the default order, put it at the end
                return 1;
            }

            if (indexB === -1) {
                // b is not in the default order, put it at the end
                return -1;
            }

            // both are in the default order, sort them based on the order
            return indexA - indexB;
        });

        return traitTypes;
    }


    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    }

    function refreshOrdinals() {
        setOrdinals(shuffleArray([...dataSource.ordinals]));
    }

    useEffect(() => {
        setOrdinals(shuffleArray([...dataSource.ordinals]));
    }, [dataSource]);

    const uniqueTraitTypes = getUniqueTraitTypes(ordinals);

    const filterSections = uniqueTraitTypes.map((traitType) => (
        <div className="filter-section" key={traitType.replace('_', ' ')}>
            <h3 className={expanded.includes(traitType) ? 'expanded' : ''} onClick={() => handleExpandCollapse(traitType)}>
                <div className="icon-svg">{renderIcon(traitType)}</div>
                {traitType.replace('_', ' ')}
                <PlusMinusButton isExpanded={expanded.includes(traitType)} />
            </h3>
            {expanded.includes(traitType) && (
                <div className="filter-options">
                    <div className="expand-search-bar">
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchTerms[traitType]}
                            onChange={(e) => handleCategorySearchInputChange(traitType, e.target.value)}
                        />
                    </div>
                    {getUniqueValues(traitType).map((value) => {
                        const count = getTraitCount(traitType, value);
                        return (
                            <label key={value} className="checkbox-container">
                                <span className="label-text">{value} ({count})</span>
                                <input
                                    type="checkbox"
                                    name={traitType.replace('_', ' ')}
                                    value={value}
                                    checked={filters[traitType].includes(value)}
                                    onChange={handleFilterChange}
                                />
                                <span className="checkmark"></span>
                            </label>
                        );
                    })}
                </div>
            )}
        </div>
    ));

    const sortOrdinalsByRarityRank = useCallback((rank) => {
        return allOrdinals.sort((a, b) => a.rarityrank - b.rarityrank).slice(0, rank);
    }, [allOrdinals]); // use allOrdinals here

    const handleTop1ButtonClick = (event) => {
        event.preventDefault();
        if (lastAppliedFilter === 'Top 1%') {
            setOrdinals(shuffleArray(allOrdinals));
            setLastAppliedFilter(null);
        } else {
            setOrdinals(sortOrdinalsByRarityRank(100));
            setLastAppliedFilter('Top 1%');
        }
    };

    const handleTop10ButtonClick = (event) => {
        event.preventDefault();
        if (lastAppliedFilter === 'Top 10%') {
            setOrdinals(shuffleArray(allOrdinals));
            setLastAppliedFilter(null);
        } else {
            let sortedOrdinals = sortOrdinalsByRarityRank(1000);
            let topOrdinals = sortedOrdinals.slice(100); // exclude first 100
            setOrdinals(topOrdinals);
            setLastAppliedFilter('Top 10%');
        }
    };

    const handleTop25ButtonClick = (event) => {
        event.preventDefault();
        if (lastAppliedFilter === 'Top 25%') {
            setOrdinals(shuffleArray(allOrdinals));
            setLastAppliedFilter(null);
        } else {
            let sortedOrdinals = sortOrdinalsByRarityRank(2500);
            let topOrdinals = sortedOrdinals.slice(1000); // exclude first 1000
            setOrdinals(topOrdinals);
            setLastAppliedFilter('Top 25%');
        }
    };

    return (
        <div className="gallery-container">
            <form className={`filter-parent${filterVisible ? '' : ' hidden'}`}>
                <div className="filter-padding"></div>
                <div className="filter-header">
                    <h1>ㅤ</h1>
                </div>
                <div className="filter-container">
                    <div className="mobile-filter-header">
                        <h1 className="mobile-filter-title">FILTERS</h1>
                        <button className="mobile-filter-close" type="button" onClick={handleCloseButtonClick}>
                            X
                        </button>
                    </div>
                    <div className="search-bar-container">
                        <div className="search-icon-svg">
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fillRule="evenodd" clipRule="evenodd" d="M10 5C7.23858 5 5 7.23858 5 10C5 12.7614 7.23858 15 10 15C11.381 15 12.6296 14.4415 13.5355 13.5355C14.4415 12.6296 15 11.381 15 10C15 7.23858 12.7614 5 10 5ZM3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 11.5719 16.481 13.0239 15.6063 14.1921L20.7071 19.2929C21.0976 19.6834 21.0976 20.3166 20.7071 20.7071C20.3166 21.0976 19.6834 21.0976 19.2929 20.7071L14.1921 15.6063C13.0239 16.481 11.5719 17 10 17C6.13401 17 3 13.866 3 10Z" fill="#000000"></path> </g></svg>
                        </div>
                        <input
                            type="text"
                            placeholder="Search # or inscription id..."
                            value={searchTerm}
                            onChange={handleSearchInputChange}
                        />
                    </div>
                    <div className='buttons-flex'>
                        <button className='top1-button' onClick={handleTop1ButtonClick}>Top 1%</button>
                        <button className='top10-button' onClick={handleTop10ButtonClick}>Top 10%</button>
                        <button className='top25-button' onClick={handleTop25ButtonClick}>Top 25%</button>
                    </div>
                    {filterSections}
                </div>
            </form>
            <div className="grid-main">
                <div className="grid-header-container">
                    <div className="grid-header">
                        <div className="switch-container">
                            <button
                                className={`og-switch${activeDataSource === 'another' ? ' active-switch' : ''}`}
                                onClick={switchToAnotherDataSource}
                            >
                                <span className="switch-title">BEARS</span>
                            </button>
                            <span className="switch-gap"></span>
                            <button
                                className={`new-switch${activeDataSource === 'original' ? ' active-switch' : ''}`}
                                onClick={switchToOriginalDataSource}
                            >
                                <span className="switch-title">CUBS</span>
                            </button>

                        </div>
                        <div className="ordinal-count-refresh">
                            <span className="ordinal-count">{filteredOrdinals.length}</span>
                            <button className="refresh-button" onClick={refreshOrdinals}>
                                <svg className="refresh-svg" fill="#000000" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M7 12v-2l-4 3 4 3v-2h2.997A6.006 6.006 0 0 0 16 8h-2a4 4 0 0 1-3.996 4H7zM9 2H6.003A6.006 6.006 0 0 0 0 8h2a4 4 0 0 1 3.996-4H9v2l4-3-4-3v2z" fillRule="evenodd"></path> </g></svg>
                            </button>
                        </div>
                    </div>
                    <div className="filter-button" onClick={handleFilterButtonClick}>
                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000">
                            <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                            <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <path d="M20.17 3.91C20.1062 3.78712 20.0101 3.68399 19.8921 3.61173C19.774 3.53947 19.6384 3.50084 19.5 3.5H4.5C4.36157 3.50084 4.226 3.53947 4.10792 3.61173C3.98984 3.68399 3.89375 3.78712 3.83 3.91C3.76636 4.03323 3.73915 4.17204 3.75155 4.31018C3.76395 4.44832 3.81544 4.58007 3.9 4.69L9.25 12V19.75C9.25259 19.9481 9.33244 20.1374 9.47253 20.2775C9.61263 20.4176 9.80189 20.4974 10 20.5H14C14.1981 20.4974 14.3874 20.4176 14.5275 20.2775C14.6676 20.1374 14.7474 19.9481 14.75 19.75V12L20.1 4.69C20.1846 4.58007 20.236 4.44832 20.2484 4.31018C20.2608 4.17204 20.2336 4.03323 20.17 3.91Z" fill="#000000"></path>
                            </g>
                        </svg>
                    </div>
                </div>
                <div className="filter-results">
                    <h3>Filters</h3>
                    <span className="filter-count">
                        <div className="filter-count-text">
                            {appliedFilters.length}
                        </div>
                    </span>
                    {appliedFilters.map((filter) => (
                        <span key={filter} className="applied-filter">
                            {filter}
                            <button
                                type="button"
                                className="remove-filter"
                                onClick={() => removeFilter(filter)}
                            >
                                X
                            </button>
                        </span>
                    ))}
                </div>
                <div className="ordinals-grid">
                    {filteredOrdinals.slice(0, itemsToShow).map((ordinal) => (
                        <Ordinal key={ordinal.id} ordinal={ordinal} showDownloadButton={activeDataSource === "original"} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Gallery;

