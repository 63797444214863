import React, { useEffect, useState } from 'react';
import './Holders.css';
import addressData from './address.json';

const Holders = () => {
    const [holders, setHolders] = useState([]);
    const [totalAddresses, setTotalAddresses] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const holdersPerPage = 50;

    useEffect(() => {
        const fetchHolders = async () => {
            const response = await fetch('https://polar-castle-78796.herokuapp.com/proxy');
            const data = await response.json();
            const sortedData = data.sort((a, b) => b.holds - a.holds);

            // Create a list of addresses to be excluded
            const excludedAddresses = addressData.map(item => item.address);

            // Filter out the excluded addresses
            const filteredData = sortedData.filter(holder => 
                !excludedAddresses.includes(holder.address)
            );

            const renamedData = filteredData.map(holder => ({
                ...holder,
                name: getAddressName(holder.address)
            }));

            setHolders(renamedData);
            setTotalAddresses(renamedData.length);
        };

        fetchHolders();
    }, []);

    const indexOfLastHolder = currentPage * holdersPerPage;
    const indexOfFirstHolder = indexOfLastHolder - holdersPerPage;
    const currentHolders = holders.slice(indexOfFirstHolder, indexOfLastHolder);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const getAddressName = (address) => {
        const foundAddress = addressData.find(item => item.address === address);
        return foundAddress ? foundAddress.name : address;
    };


    return (
        <div className="entire-page">
            <div className="holders-background">
                <div className='holder-sheet'>
                    <div className='collection-stats'>
                        <div className='title-gap'></div>
                        <div className='supply-title'>
                            <h3>Total Supply</h3>
                            10,000 BBC
                        </div>
                        <div className='holder-title'>
                            <h3>Holders</h3>
                            {totalAddresses}
                        </div>
                        <div className='link-title'>
                            <h3>Collection Link</h3>
                            <a href="https://magiceden.io/ordinals/marketplace/bbc" target="_blank" rel="noreferrer">
                                <img className="magic-eden-logoo" src="/MELOGO.png" alt="Logo" />
                            </a>
                            <a href="https://bestinslot.xyz/ordinals/collection/bbc" target="_blank" rel="noreferrer">
                                <img className="magic-eden-logoo" src="/bis.png" alt="Logo" />
                            </a>
                        </div>
                        <div className='title-gap'></div>
                    </div>
                    <div className='holder-box'>
                        <div className='holder-row'>
                            <p className='holder-number'>Rank</p>
                            <p className='address-title'>Address</p>
                            <p className='holder-holds'>Quantity</p>
                            <p className='holder-percent'>Percentage</p>
                        </div>
                        {currentHolders.map((holder, index) => (
                            <div key={index} className='holder-row'>
                                <p className='holder-number'>{indexOfFirstHolder + index + 1}</p>
                                <a href={`https://magiceden.io/ordinals/wallet?walletAddress=${holder.address}`} target="_blank" rel="noreferrer" className='holder-address'>{holder.name}</a>
                                <p className='holder-holds'>{holder.holds}</p>
                                <p className='holder-percent'>{(holder.holds / 10000 * 100).toFixed(2)}%</p>
                            </div>
                        ))}
                        <div className='pagination-controls'>
                            <button onClick={() => paginate(1)} disabled={currentPage === 1} className="arrow">First</button>
                            <button onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} className="arrow">&lt;</button>
                            <p>Page {currentPage} of {Math.ceil(holders.length / holdersPerPage)}</p>
                            <button onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(holders.length / holdersPerPage)} className="arrow">&gt;</button>
                            <button onClick={() => paginate(Math.ceil(holders.length / holdersPerPage))} disabled={currentPage === Math.ceil(holders.length / holdersPerPage)} className="arrow">Last</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Holders;

